<template>
  <input type="text" class="form-control" :class="{ 'is-invalid': hasError }" :id="inputId" :aria-describedby="helpTextId" :placeholder="placeholder" :maxlength="maxlength" :value="value" @input="handleChange">
</template>

<script>
import inputMixin from './inputMixin'

export default {
  props: {
    maxlength: String
  },
  mixins: [
    inputMixin
  ]
}
</script>
